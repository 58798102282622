@import url('../common.css');

a:link { text-decoration: none; }

.navigation_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(50px + 1rem);
    padding-top: 10px;
    margin-left: none;
    padding-bottom: 5rem;
    min-height: 700px;
}

.navigation_section{
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 1.5rem;
    margin-bottom: .5rem;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.navigation_special_section_title{
    padding: .5rem 0rem 0rem 0rem;
    font-family: base-font;
    color: #7a2897;
    font-size: 16pt;
    text-align: center;
}

.navigation_special_section_subtitle{
    padding: 2rem 0rem .3rem 0rem;
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 12pt;
    text-align: center;
}

.navigation_special_section_instruction{
    padding: 0rem 0rem 2rem 0rem;
    font-family: base-font;
    color: rgb(84, 84, 84);
    font-size: 9pt;
}

.navigation_section_title{
    padding: .5rem .5rem .5rem .5rem;
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 14pt;
}

.navigation_section_title:hover{
    cursor: pointer;
    background-color: #7928973e;
}

.navigation_flex_section{
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.navigation_flex_container{
    cursor: pointer;
    text-align: center;
    font-family: base-font;
    word-wrap: normal;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;

}



.navigation_element_icon{
    height: 54px;
    margin: 10px;
    border-radius: 5px;
    filter: grayscale();
}

.navigation_title{
    color: rgb(60, 60, 60);
    font-size: 11pt;
    font-family: base-font;
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    margin-bottom: 10px;
    border-radius: 15px;
    text-align: center;
    vertical-align: middle;
    height: 74px;
    line-height: 74px;
}



  .navigation_district_selection{
    display: grid;
    grid-template-columns: 120px 1fr;
    margin-bottom: 10px;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    border-radius: 15px 15px 15px 15px;
    color: rgb(66,66,66);
  }

  .navigation_district_selection_highlight{
    background-color: #845595;
    color: rgb(255, 255, 255);
  }

  .navigation_option{
    font-family: base-font;
    font-size: 12pt;
    height: 54px;
    line-height: 54px;
    vertical-align: middle;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    border-radius: 15px 15px 15px 15px;
    color: rgb(66,66,66);
    margin-bottom: 10px;
  }


  .navigation_option_highlight{
    background-color: #845595;
    color: rgb(255, 255, 255);
  }

@media only screen and (min-width: 800px) {

    .navigation_special_section_title{
        font-size: 18pt;
    }

    .navigation_special_section_subtitle{
        font-size: 14pt;
    }

    .navigation_special_section_instruction{
        font-size: 11pt;
    }


    .navigation_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }


    .navigation_district_grid{

        grid-template-columns: 170px 1fr;
      }

    .navigation_element_icon{
        height: 75px;
    }

    .navigation_section{
        border-radius: 15px;
    }

}
