@import url('../common.css');

.header_section{
  z-index: 3;
  background: rgb(22, 22, 22);
  position: fixed;
  width: 100vw;
  height: 70px;
  left: 0;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 60px 5fr 70px;
}

.header_voterstack_logo{
    width: 50px;
    animation: fadeInBox 2s;
    margin-left: 5px;
    padding: 5px 5px 5px 5px;

}

.header_voterstack_name{
    font-size: 22pt;
    color: rgb(232, 232, 232);
    font-family: base-font;
    line-height: 70px;
    margin-left: 2px;
}

.login_logo_section{
    display: grid;
    grid-template-columns: 55px 1fr;
    cursor: pointer;
}



.login_main_menu_text{
  margin: 0px;
}





 /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
 .flip-card {
    background-color: transparent;
    height: 70px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  


  .front-flip-animation{
    transform: rotateX(180deg);

  }

  .back-flip-animation{
    transform: rotateX(0deg);

  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background: rgb(22, 22, 22);
    color: black;
  }
  
  /* Style the back side */
  .flip-card-back {
    background: rgb(22, 22, 22);
    text-align: center;
    vertical-align: middle;
    line-height: 70px;
    min-width: 270px;
    font-size: 22pt;
    color: white;
    font-family: base-font;
    transform: rotateX(180deg);
  } 