@import url('../common.css');

a:link { text-decoration: none; }


.activity_history_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(50px + 1rem);
    padding-top: 10px;
    padding-bottom: 5rem;
    min-height: 600px;
}

.activity_history_section{
    background: rgb(255, 255, 255);
    max-width: 800px;
    margin-bottom: .5rem;
    padding: 10px;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.activity_history_main_title{
    color: rgb(255, 255, 255);
    font-size: 16pt;
    font-family: base-font;
    padding: 0rem 1rem .5rem 1rem;
}

.activity_history_snippet_when{
    color: rgb(84, 84, 84);
    font-size: 12pt;
    font-family: base-font;
    padding-bottom: 5px;
}

.activity_history_snippet_session{
    color: rgb(84, 84, 84);
    font-size: 10pt;
    font-family: base-font;

}

.activity_history_snippet_what{
    color: #845595;
    font-size: 10pt;
    font-family: base-font;
    padding-bottom: 10px;
}




.activity_history_snippet_content{
    color: rgb(0, 0, 0);
    font-size: 12pt;
    font-family: base-font;
    background: rgb(255, 255, 255);

    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    padding: .75rem;
}

.activity_history_no_doc{
    display: block;
    margin: 0 auto;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.activity_history_text{
    font-family: base-font;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12pt;
    color: rgb(66,66,66);
    text-align: center;
}

@media only screen and (min-width: 800px) {

    .activity_history_main_title{
        color: rgb(60,60,60);
    }


    .activity_history_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }

    .activity_history_section{
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75); 
        padding: 20px;
        margin-bottom: 1.5rem;
        border-radius: 15px;
    }

}