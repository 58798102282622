@import url('../common.css');

a:link { text-decoration: none; }


.user_settings_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(50px + 1rem);
    padding-top: 10px;
    padding-bottom: 5rem;
    min-height: 600px;
}

.user_settings_section{
    background: rgb(255, 255, 255);
    max-width: 800px;
    margin-bottom: .5rem;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.user_settings_section_title{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;
}


.user_settings_selection{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 12pt;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.user_settings_title_grid{
    display: grid;
    grid-template-columns: 40px 1fr;

}

.user_settings_title_image{
    width: 30px;
}

.user_settings_title_label{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 14pt;
}



@media only screen and (min-width: 800px) {

    .user_settings_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }



    .user_settings_section{
        border-radius: 15px;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }

    .user_settings_title_grid{
        grid-template-columns: 40px 1fr;
    }

    .user_settings_title_label{

        line-height: 30px;
    }

    .user_settings_title_image{
        width: 30px;
    }

}
