@import url('../../common.css');

.review_information_user_entry{
    margin-top: .5rem;
    text-align: left;
    font-family: base-font;
    font-size: 12pt;
    color: rgb(158, 158, 158);
    margin-bottom: .5rem;
}

.review_information_image_combination{
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
}

.review_information_proof_image{
    width: 100%;
}

.review_information_proof_headers{
    text-align: center;
    font-family: base-font;
    font-size: 14pt;
    color: rgb(77, 77, 77);
    margin-top: .5rem;
    margin-bottom: .5rem;
}