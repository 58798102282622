@import url('../common.css');

a:link { text-decoration: none; }

.policy_debate_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(50px + 1rem);
    padding-top: 10px;
    margin-left: none;
    padding-bottom: 5rem;
    min-height: 600px;
    padding-left: .5rem;
    padding-right: .5rem;
    overflow-y: scroll;
    max-width: 800px;
}

.policy_debate_section{

    border-radius: 15px;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .75rem;
    padding-bottom: .75rem;

    box-sizing: border-box;
    max-width: 800px;
    margin-bottom: 1rem;
    background: rgb(255, 255, 255);

    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}

.policy_debate_do_you_support_text{
    font-size: 12pt;
    text-align: center;
    padding: 0rem 1rem 2rem 1rem;
    color: rgb(60, 60, 60);
    font-family: base-font;
}

.policy_debate_button_container{
    grid-template-columns: 1fr 1fr;
    display: grid;
    box-sizing: border-box;
    width: 100%;
}

.policy_debate_button{
    border-radius: 5px;
    cursor: pointer;
    font-size: 12pt;
    background: rgb(244, 244, 244);

    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    cursor: pointer;
    padding: .5rem;
    text-align: center;
    font-size: 12pt;
    color: rgb(60, 60, 60);
    margin-bottom: .5rem;
}

.policy_debate_button_agree{
    background-color: rgb(100, 216, 71);
    color: white;
}

.policy_debate_button_disagree{
    background-color: rgb(216, 71, 71);
    color: white;
}

.policy_debate_button_submit{
    background-color: rgb(137, 27, 167);
    color: white;
}


.policy_debate_choice_image{
    margin: 0 auto;
    text-align: center;
    height: 30px;
    margin-bottom: 1rem;

}

.bounce2 {
    animation: bounce2 1s ease;
  }
  @keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-30px);}
      60% {transform: translateY(-15px);}
  }

  .policy_debate_provide_context_text{
    font-size: 12pt;
    text-align: center;
    padding: 0rem 1rem 2rem 1rem;
    color: rgb(60, 60, 60);

    font-family: base-font;
}



  .policy_debate_text_area{
    font-family: base-font;
    font-size: 12pt;
    padding: .5rem;
    width: 100%;
    height: 8rem;
    box-sizing: border-box;
    resize: none;
  }

  .policy_debate_myArgument_selected{
    border: 1px rgb(173, 173, 173) solid;
    border-radius: 25px;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .75rem;

    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    max-width: 800px;
    margin-bottom: 2rem;

  }

  .policy_debate_myArgument_selected_title{
    color: rgb(60, 60, 60);
    text-align: center;
    padding-bottom: .5rem;
    font-family: base-font;
    font-size: 14pt;
  }

  .policy_debate_text_area{
    height: 10px;
    transition: .5s height;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    min-height: 100px;
    max-height: 200px;
    font-size: 12pt;
    font-family: base-font;
    background: rgb(244, 244, 244);
    border-style: none;
    border-color: Transparent;
    overflow: auto;
    outline: none;
}

.policy_debate_character_count{
    color: rgb(73, 73, 73);
    text-align: right;

  }
  

  .policy_debate_filter_button{
    padding: .5rem;
    color: white;
    background-color: rgb(156, 90, 182);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    font-size: 30pt;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }

  .policy_debate_filter_container_bar{
    display: grid;
    grid-template-columns: 6fr 85px;
    height: 75px;
    z-index: 2;
    bottom: 0;
    position: absolute;
    width: 100%;
    max-width: 800px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }





@media only screen and (min-width: 800px) {

    .policy_debate_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }

    .policy_debate_choice_image{
        height: 45px;
        margin-bottom: 1.5rem;
    }

    .policy_debate_section{

        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;

    }

}
