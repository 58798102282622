@import url('../common.css');

a:link { text-decoration: none; }



.fade-in-left {
    animation: fade-in-left 2s ease;
  }
  @keyframes fade-in-left {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }




  
  /* CSS talk bubble */
  .user_argument_bubble {
    display: inline-block;
    position: relative;
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
    margin-top: 5px;
    background: rgb(255, 255, 255);
    padding: .75rem;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  }




  .round{
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
  }
  
  /*Right triangle, placed bottom left side slightly in*/

  


/* ==================================================== */


.user_argument_creation_text{
  font-size: 8pt;
  color: rgb(0, 0, 0);
  font-family: base-font;

}


.mark_as_my_argument_button{
  border-radius: 15px;
  text-align: center;
  font-size: 10pt;
  color: rgb(58, 58, 58);

  font-family: base-font;
  padding: 1px 6px 1px 6px;
  margin-top: 10px;
  background-color: rgb(186, 186, 186);
  padding: .25rem;
}



.user_argument_position{
  border-radius: 15px;
  text-align: center;
  font-size: 6pt;
  color: white;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  font-family: base-font;
  padding: 1px 6px 1px 6px;
  margin-right: 5px;
}

.user_argument_statement{

  color: rgb(59, 59, 59);
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-family: base-font;
  margin-bottom: 25px;
}


.user_argument_supporter{
  border-color: rgb(47, 119, 47);
  background-color: rgb(88, 185, 88);
}

.user_argument_opposer{
  border-color: rgb(128, 72, 72);
  background-color: rgb(185, 85, 85);
}

.user_argument_flex{
  display: flex;
  
  margin-bottom: 25px;
}

.user_argument_grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}



@media only screen and (min-width: 800px) {



  .top_priority_text{
    font-size: 16pt;
  }

  .top_priority_badge{
    width: 65px;
  }


  .mark_as_priority_button{
    padding: .5rem;
  }


}