@import url('../common.css');

a:link { text-decoration: none; }

.policy_filter_overlay{
    background-color: rgba(0, 0, 0, 0.938);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 4;
}

.policy_filter_title{
    color: white;
    text-align: center;
    font-size: 20pt;
    font-family: base-font;

}

.policy_filter_rows{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: .25rem;
}

.policy_filter_element{
    color: white;
    text-align: center;
    font-size: 14pt;
    font-family: base-font;
    padding: .5rem;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    border-radius: 15px;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: black;
}

.policy_filter_highlight{
    color: white;
    text-align: center;
    font-size: 14pt;
    font-family: base-font;
    padding: .5rem;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 15px;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 1rem;
    cursor: pointer;
    color: black;
    background-color: rgb(255, 255, 255);
}

.policy_filter_element_cancel{
    color: white;
    border-color: #845595;
    margin-top: 1rem;
    cursor: pointer;
}

.policy_filter_options_center{
    padding-top: calc(100vh/9);
}