@import url('../../common.css');

a:link { text-decoration: none; }

.voter_registration_section{

    padding: 10px;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-sizing: border-box;
    max-width: 800px;
    margin-bottom: .5rem;
    animation: fadeInBox 1s;
    padding-top: 20px;
    padding-bottom: 20px;
}

.voter_registration_title{
    color: rgb(66, 66, 66);
    font-family: base-font;
    padding-bottom: 20px;
    font-size: 12pt;
}

.voter_registration_address_box{
    background-color: rgb(241, 241, 241);
    padding: 10px;
    color: rgb(66, 66, 66);
    font-family: base-font;
    font-size: 10pt;
    margin-bottom: 30px;
}


.voter_registration_notice{
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgb(66, 66, 66);
    font-family: base-font;
    font-size: 10pt;
}

.voter_registration_state_links{
    padding-top: 30px;
    padding-bottom: 30px;
    color: rgb(101, 31, 158);
    font-family: base-font;
    font-size: 12pt;
}


@media only screen and (min-width: 800px) {


    .voter_registration_section{
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }

}
