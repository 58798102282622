@import url('../common.css');

.error_window_section{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    width: 80%;
    border-radius: 5px;
    animation: fadeIn 1s;
}

.error_window_title{
    color: rgb(77, 77, 77);
    font-size: 16pt;
    margin-bottom: 1rem;
    font-family: base-font;
    text-align: center;
}

.error_window_description{
    color: rgb(59, 59, 59);
    font-family: base-font;
    text-align: center;
    margin-bottom: 30px;
}

.error_window_icon_center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    width: 50px;
}

@media only screen and (min-width: 600px) {
    .error_window_section{
        width: 500px;
    }

}