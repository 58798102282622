@import url('../common.css');

a:link { text-decoration: none; }

.my_policy_background{
    max-width: 800px;
    width: 100%;
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    margin-bottom: 1rem;

}


.add_policy_text_big{
    font-size: 12pt;
    color: rgb(60,60,60);
    font-family: base-font;
    line-height: 30px;
    margin-top: .25rem;
    margin-bottom: 1rem
}

.add_policy_outer_container{
  
 border-color: rgba(189, 189, 189, 0.75);
 border-style: solid;
 border-width: 2px;
  border-radius: 25px;
  padding-left: .75rem;
  padding-right: .75rem;

  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  max-width: 800px;
  margin-bottom: .5rem;
}

.add_policy_inner_container{
    padding: .5rem;
    text-align: center;
    border-radius: 15px;
    margin-bottom: -.75rem;
    cursor: pointer;
}

.my_district_policy_title{
  color: rgb(60, 60, 60);
  text-align: center;
  padding-bottom: .5rem;
  font-family: base-font;
  font-size: 14pt;
  margin-top: 15px;
}

.my_policy_edit_creation{
  color: rgb(60, 60, 60);
  text-align: right;
  font-family: base-font;
  font-size: 11pt;
  padding-right: .25rem;
  cursor: pointer;
  width: 200px;
  right: 0;
  float: right;

  animation: fadeIn 2s;
}


  
.fade-in-left {
    animation: fade-in-left 2s ease;
  }
  @keyframes fade-in-left {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }

    
.fade-out {
  animation: fade-out-animation .5s forwards;
}

@keyframes fade-out-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}



@media only screen and (min-width: 800px) {

  .add_policy_outer_container{
      padding-left: 1rem;
      padding-right: 1rem;
  }

  .my_policy_edit_creation{
    margin-top: -15px;

  }


}





