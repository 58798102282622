@import url('../common.css');

a:link { text-decoration: none; }

.address_search_box{
    min-height: 44px;
    margin-top: 25px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    border-color: rgba(0, 0, 0, 0.87);
}