@import url('../common.css');

.how_it_works_title{
    color: rgb(92, 92, 92);
    text-align: center;
    margin-bottom: 3rem;
    font-size: 26pt;
}

.how_it_works_section_header{
    color: rgb(92, 92, 92);
    text-align: center;
    border-radius: 15px 15px 15px 15px;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}

.how_it_works_text{
    font-size: 11pt;
    text-align: center;
    margin-bottom: 4rem;

}

.how_it_works_icon{
    height: 80px;
    width: 80px;

}