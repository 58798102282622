@import url('../common.css');

a:link { text-decoration: none; }

.policy_select_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(80px);
    margin-left: none;
    padding-bottom: 5rem;
    max-width: 800px;
    
}




.policy_select_no_policy_text{
    font-family: base-font;
    font-size: 14pt;
    color: rgb(88, 88, 88);
    text-align: center;
    padding: 2rem;
}



.policy_select_policy_text{
    font-family: base-font;
    color: rgb(0, 0, 0);
    font-size: 11pt;
    margin-bottom: 1rem;
}

.vote_priority_button{
    margin: auto;
    width: 100%;
    background-color: #845595;
    color: white;
    font-size: 14pt;
    padding: 10px;
    text-align: center;
    margin-top: 1rem;
    box-sizing: border-box;
    font-family: base-font;
}

.policy_select_chosen_container{
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0, 0, 0);
    -moz-box-shadow: 0px 0px 5px 0px rgb(0, 0, 0);
    box-shadow: 0px 0px 5px 0px rgb(0, 0, 0);
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    box-sizing: border-box;
    max-width: 800px;
    margin-bottom: 2rem;
    background-color: white;
}

.policy_select_chosen_title{
    color: rgb(60,60,60);
    text-align: center;
    margin-top: 10px;
    padding-bottom: .5rem;
    font-family: base-font;
    font-size: 14pt;
  }

  .policy_select_filter_button{
    padding: .5rem;
    color: white;
    background-color: #845595;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    font-size: 30pt;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }

  .policy_select_filter_container_bar{
    display: grid;
    grid-template-columns: 6fr 85px;
    height: 75px;
    z-index: 2;
    bottom: 0;
    position: absolute;
    width: 100%;
    max-width: 800px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }





@media only screen and (min-width: 800px) {

    .policy_select_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }



    .policy_select_filter_button{
        width: 50px;
        height: 50px;
        font-size: 30pt;
        line-height: 50px;
      }
    
      .policy_select_filter_container_bar{
        height: 70px;
        grid-template-columns: 6fr 95px;
        margin-bottom: 30px;
      }

      .policy_select_filter_select_bar{
        height: 86px;
      }

}
