@import url('../common.css');

.redirect_logout_section{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    width: 80%;
    border-radius: 5px;
}



.redirect_logout_description{
    color: rgb(59, 59, 59);
    font-family: base-font;
    text-align: center;
}



@media only screen and (min-width: 600px) {
    .redirect_logout_section{
        width: 500px;
    }

}