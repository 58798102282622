@import url('https://fonts.googleapis.com/css?family=Lato');



.countdown-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.countdown-item {
	color: #111;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
    line-height: 20px;
	margin: 5px;
    padding-top: 5x;
	position: relative;
	width: 100px;
	height: 100px;
}

.countdown-item span {
	color: #333;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
}

.countdown-svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
	stroke: #845595;
}

