@import url('../common.css');

.official_thread_section{
    display: block;
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    padding: .5rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    margin-bottom: .5rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeIn .5s;
    margin: 0 auto;
    height: 100%;
    position: relative;
}

.official_thread_close_button{
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    font-size: 12pt;
    padding: 10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    font-family: base-font;
    color: rgb(60,60,60);
    display: block;
    width: calc(100% - 40px);
    box-sizing: inherit;
    height: 40px;
    position: absolute;
    bottom: 0;
    margin: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.official_thread_close_button:hover{
    background-color: rgb(60,60,60);
    color: white;
}

.official_thread_scroll_section{
    background-color: rgb(243, 243, 243);
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;
}



.official_thread_response_section{
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    animation: fadeIn 2s;
}

.official_thread_name_and_title_grid{
    display: grid;
    grid-template-columns: 50px 1fr;
}

.official_thread_name_and_title_image{
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

.official_thread_name_and_title_container{
    padding-left: 10px;
    padding-right: 10px;
}

.official_thread_name{
    font-family: base-font;
    font-size: 14pt;
    color: rgb(66, 66, 66);
}

.official_thread_title{
    font-family: base-font;
    font-size: 10pt;
    color: rgb(121, 121, 121);
}


.official_thread_posted_date{
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: base-font;
    font-size: 10pt;
    color: rgb(121, 121, 121);

}

.official_thread_response{
    font-family: base-font;
    font-size: 12pt;
    color: rgb(66, 66, 66);
    padding: 15px;
    background-color: rgb(242, 242, 242);
}

@media only screen and (min-width: 800px) {
    .official_thread_section{
        width: 800px;
    }

}