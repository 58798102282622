.identity_proof_example_id{
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
}

.identity_proof_id_preview{
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
}

@import url('../../common.css');