@import url('../common.css');

.non_responsive_section{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px 40px 40px 40px;
    width: 88%;
    border-radius: 5px;
    animation: fadeIn 1s;
}

.non_responsive_profile_center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 75px;
    width: 75px;
    border-radius: 50%;
}

.non_responsive_name{
    text-align: center;
    font-family: base-font;
    font-size: 16pt;
    color: #845595;
    padding: 5px;
}

.non_responsive_would_you_contact{
    margin-top: 10px;
    font-family: base-font;
    font-size: 12pt;
    color: #000000;
    padding-top: 10px;
}

.non_responsive_description{
    text-align: center;
    font-family: base-font;
    font-size: 11pt;
    color: rgb(66,66,66);
    padding: 5px;
}




.non_responsive_image_title_grid{
    display: grid;
    grid-template-columns: 25px 1fr;
    margin-bottom: 15px;
}

.non_responsive_contact_image{
    width: 20px;
}

.non_responsive_contact_label{
    font-family: base-font;
    font-size: 13pt;
    color: rgb(66, 66, 66);
    line-height: 15px;
}



.non_responsive_title_grid{
    display: grid;
    grid-template-columns: 40px 1fr;
}

/* Yes  */

.non_responsive_title_image{
    width: 30px;
}

.non_responsive_title_label{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;
}

.non_responsive_character_count{
    color: rgb(73, 73, 73);
    text-align: right;

  }




@media only screen and (min-width: 600px) {
    .non_responsive_section{
        width: 500px;
    }



}