@import url('../common.css');



.first_time_screen_background{
    z-index: 0;
    height: 100vh;
    height: calc(80vh);
    overflow-y: scroll;

}

.first_time_section{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem;
    width: 85%;
    border-radius: 5px;
    animation: fadeIn 1s;
    padding-bottom: 2rem;
}

.first_time_title{
    color: rgb(77, 77, 77);
    font-size: 16pt;
    margin-bottom: 1rem;
    font-family: base-font;
    text-align: center;
}

.first_time_subtitle{
    color: rgb(139, 58, 180);
    font-size: 12pt;
    margin-bottom: 1rem;
    font-family: base-font;
    margin-top: 15px;
    margin-bottom: 15px;
}

.first_time_description{
    color: rgb(59, 59, 59);
    font-family: base-font;
    font-size: 10pt;
    text-align: center;
    margin-bottom: 30px;
}

.first_time_text{
    color: rgb(59, 59, 59);
    font-family: base-font;
    font-size: 10pt;
    margin-bottom: 30px;
}

.first_time_icon_center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    width: 50px;
}

@media only screen and (min-width: 600px) {
    .first_time_section{
        width: 500px;
        padding: 2rem;
    }

    .first_time_screen_background{

        height: calc(85vh);

    
    }
}