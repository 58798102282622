@import url('../common.css');

a:link { text-decoration: none; }

.support_screen_foreground{
    display: block;
    padding-top: calc(100vh/6);

}
.support_section{

    background-color: rgb(255, 255, 255);
    padding: 2rem;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;

}

.support_instructions{
    font-family: base-font;
    font-size: 11pt;
    padding-top: 10px;
    padding-bottom: 20px;
  }

.support_title_grid{
    display: grid;
    grid-template-columns: 40px 1fr;
}

/* Yes  */

.support_title_image{
    width: 30px;
}

.support_title_label{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;
}

.support_image_title_grid{
    display: grid;
    grid-template-columns: 25px 1fr;
    margin-bottom: 15px;
}

.support_contact_image{
    width: 20px;
}

.support_contact_label{
    font-family: base-font;
    font-size: 13pt;
    color: rgb(66, 66, 66);
    line-height: 15px;
}

.support_section_title{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;

}



@media only screen and (min-width: 600px) {


    .support_screen_foreground{
        margin-left: calc((100vw - 500px) / 2);
    }

    .support_section{
        border-radius: 15px;
        max-width: 500px;
    }

}