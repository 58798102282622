  
  @import url('../common.css');
  
  /* CSS talk bubble */
  .create_policy_bubble {
    display: block;
    position: relative;
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 35px;
    margin-top: 5px;
    padding: 0rem;
    transition: .5s padding;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  }



  .create_policy_round{
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
  
  }
  


  .create_policy_text_area{
    height: 10px;
    transition: .5s height;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    min-height: 50px;
    max-height: 75px;
    font-size: 12pt;
    font-family: base-font;

    border-style: none;
    border-color: Transparent;
    overflow: auto;
    outline: none;

}


.create_policy_directions{
    color: rgb(60,60,60);
    text-align: left;
    padding: .25rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s;
    font-size: 12pt;
    font-family: base-font;
}

.create_policy_cancel_submit_container{
    display: grid;
    grid-template-columns: 3fr 10px 3fr;
}

.create_policy_button{
  text-align: center;

  font-family: base-font;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  cursor: pointer;
  font-size: 12pt;
  padding: .25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: rgb(77, 77, 77);

  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    padding-left: 30%;
    padding-right: 30%;
}

.create_policy_character_count{
  color: rgb(73, 73, 73);
  text-align: right;
  margin-top: -30px;
}

@media only screen and (min-width: 800px) {



}


@keyframes fade-in-animation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }


.fade-out-animation{
    animation: fade-out .25s forwards;
}

  @keyframes fade-out {
    from {
      opacity: 100%;
    }
    to {
      opacity: 0%;
    }
  }