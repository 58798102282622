@import url('../common.css');

a:link { text-decoration: none; }



.official_response_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: 70px;
    margin-bottom: 6rem;
}

/*  */

.official_response_section{
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: .5rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    margin-bottom: .5rem;
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeIn 2s;
}



.official_response_name_title{
    font-size: 16pt;
    color: #845595;
    font-family: base-font;
    text-align: left;
}



.official_response_type_title{
    font-size: 8pt;
    color: rgb(109, 109, 109);
    font-family: base-font;
    text-align: left;

}


.official_response_full_report_button{
    text-align: center;
    margin: 0 auto;
    font-family: base-font;
    color: rgb(56, 56, 56);
    border-radius: 10px;
    cursor: pointer;
    font-size: 12pt;
    padding: .25rem;
    margin-bottom: 1rem;
    background: rgb(244, 244, 244);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}


  
  .official_response_full_report_button:hover{
    background: rgb(95, 95, 95);
    color: white;
  }


/* */

.official_response_responsive_title{
    font-size: 10pt;
    color: rgb(78, 78, 78);
}



/* Official Response officials */

.official_response_image_array{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.official_response_profile_image{
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
    border-width: 2px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    margin: .5rem;
    cursor: pointer;
}


.official_response_no_doc{
    display: block;
    margin: 0 auto;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.official_response_text{
    font-family: base-font;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 11pt;
    color: rgb(66,66,66);
    text-align: center;
}


.official_response_no_response_flex_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.official_response_no_response_flex_element{
    background-color: #845595;
    color: white;
    padding: 5px 35px 5px 35px;
    margin: 10px;
    text-align: center;
    font-size: 12pt;
    border-radius: 15px;
    font-family: base-font;
    cursor: pointer;
}



@media only screen and (min-width: 800px) {



    .official_response_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;

    }

    .official_response_section{
        padding: 1.5rem;
        padding-top: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        border-radius: 15px;
    }

    .official_statement_bubble {
        padding: 2rem;
      }
}
