
input {border:0;outline:0;}
input:focus {outline:none!important;}

.address_validate_address_entry{
    margin-top: 20px;
}

.address_validate_line_two_entry{
    padding: 16.5px 13px 16.5px 14px;
    color: rgb(33, 33, 33);
    font-family: Arial;
    text-decoration: none;
    border-color: transparent;
    font-size: 16px;

    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.00938em;
    border-color: #c4c4c4;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    margin-top: 15px;

}


.address_validate_house_icon{
    min-height: 44px;
    flex-shrink: 0;
    margin: 0px 0px 0px 18px;
    font-weight: bold;
    color: grey;
    font-size: 20pt;
}

.address_validate_line_two_entry:hover{
    border-color: rgb(0, 0, 0);
}

.address_validate_line_two_entry:focus{
    border-color: rgb(77,144,254);
    border-width: 2px;
    padding: 14.5px 13px 16.5px 14px;
}