@import url('../common.css');


.login_screen_foreground{
    display: block;
    margin-left: calc((100vw - 400px) / 2);
    padding-top: calc(100vh/5 + 20px);
}

.login_inner_section{
    width: 100vw;
    max-width: 400px;
    background-color: rgb(255, 255, 255);
    padding: 2rem;
    box-sizing: border-box;
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
    border-radius: 15px;
}

.login_logo_section{
    display: grid;
    grid-template-columns: 50px 1fr;
}

.login_voterstack_name_section{
    display: grid;
    grid-template-rows: 2fr 1fr;
    max-height: 65px;
}

.login_voterstack_name{
    font-size: 28pt;
    color: rgb(106, 106, 106);
    font-family: base-font;
    margin-left: -8px;

}

.login_voterstack_logo{
    height: 50px;
    width: 50px;
    animation: fadeInBox 2s;

}

.login_voterstack_access_title{
    font-size: 12pt;
    font-weight: lighter;
    color: rgb(86, 86, 86);
    font-family: base-font;
    padding-left: .5rem;
    vertical-align: top;
    margin-left: -8px;
    margin-top: -10px;
}

.login_title{
    font-size: 16pt;
    text-align: center;
    font-weight: lighter;
    font-family: base-font;
    color: white;
}

.login_action_links_section{
    padding-top: 1rem;
    padding-bottom: 3rem;
    width: 400px;
    
}

.login_action_links{
    display: inline-block;
    font-size: 11pt;
    font-weight: lighter;
    font-family: base-font;
    color: rgb(255, 255, 255);
    cursor: pointer;
    text-align: left;
}


.login_inline_span{
    display: inline;
}

.login_inline_span_parent{
    text-align: center;
    padding: 40px 0px 70px 0px;
}



@media only screen and (min-width: 600px) {

    .login_screen_foreground{
        margin-left: calc((100vw - 400px) / 2);
        padding-top: calc(100vh/5);
    }

    .login_action_links_section{
        padding-top: 1rem;
        padding-bottom: 3rem;
    }


    .login_inner_section{
        min-width: none;
        width: 400px;
    }

}

