@import url('../common.css');

a:link { text-decoration: none; }



.fade-in-left {
    animation: fade-in-left 2s ease;
  }
  @keyframes fade-in-left {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;

    }
  }




  
  /* CSS talk bubble */
  .user_statement_bubble {
    display: inline-block;
    position: relative;
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
    margin-top: 5px;
    background: rgb(255, 255, 255);
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  }




  .round{
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
  }
  
  /*Right triangle, placed bottom left side slightly in*/

  
  
  /* ==================================================== */

  .top_priority_badge_section{
    text-align: center;
    max-width: 800px;
    width: 100%;
  }

  .top_priority_badge{
    width: 45px;
  }

  .top_priority_text{
    padding: .5rem .0rem 1rem .0rem;
    color: rgb(255, 255, 255);
    font-family: base-font;
    font-size: 14pt;
  }



.metric_columns{
    grid-template-columns: 1fr 4px 1fr;
    display: grid;
    width: 100%;
    box-sizing: border-box;
}


/* ==================================================== */



.user_statement_anonymous{
  font-size: 10pt;
  color: rgb(255, 255, 255);

  background-color: rgb(0, 0, 0);
  font-family: base-font;
  border-radius: 0px 15px 15px 0px;
  margin-left: -1rem;
  padding: 2px 15px 2px 1rem;
  text-align: left;
  display: inline;
}



.user_statement_creation_text{
  font-size: 8pt;
  color: rgb(0, 0, 0);
  margin-bottom: .75rem;
  font-family: base-font;
}


.mark_as_priority_button{
  text-align: center;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  margin: 0 auto;
  margin-top: 1rem;
  font-family: base-font;
  color: rgb(60,60,60);
  border-radius: 10px;
  cursor: pointer;
  font-size: 12pt;
  padding: .25rem;
}

.mark_as_priority_button:hover{
  background: rgb(60,60,60);
  color: white;
}

.mark_as_priority_button_highlighted{
  background: rgb(120,120,120);
  background: linear-gradient(330deg, rgba(120,120,120,1) 11%, rgba(28,28,28,1) 68%); 
  color: white;
}



@media only screen and (min-width: 800px) {

  .top_priority_text{
    font-size: 16pt;
  }

  .top_priority_badge{
    width: 65px;
  }


  .mark_as_priority_button{
    padding: .5rem;
  }

  .user_statement_bubble {
    padding: 1.5rem;
  }

}