@import url('../common.css');

.representatives_box_section{
    padding: .5rem;
    display: grid;
    grid-template-columns: 1fr 5fr;
    background-color: rgb(247, 247, 247);
    margin-top: .5rem;
    animation: fadeInBox 1s;
}

.representatives_box_photo_section{
    height: 90px;
    padding-right: .75rem;
}

.representatives_box_name{
    font-size: 14pt;
    color: rgb(78, 78, 78);
    font-family: base-font;
}

.representatives_box_title{
    font-size: 11pt;
    color: rgb(156, 0, 187);
    font-family: base-font;
}

.representatives_box_branch{
    font-size: 11pt;
    color: rgb(120, 120, 120);
    font-family: base-font;
}
